// Generated by Framer (013b13c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-ZPULr"

const variantClassNames = {SGmNeATQX: "framer-v-1xcu7rj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({color, height, id, showVertical, width, ...props}) => { return {...props, gUDzlhgLq: showVertical ?? props.gUDzlhgLq ?? true, Rl_qLe3MC: color ?? props.Rl_qLe3MC ?? "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;color?: string;showVertical?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, Rl_qLe3MC, gUDzlhgLq, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "SGmNeATQX", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1xcu7rj", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SGmNeATQX"} ref={refBinding} style={{...style}}>{gUDzlhgLq && (<motion.div className={"framer-2rakpf"} data-framer-name={"V"} layoutDependency={layoutDependency} layoutId={"izBaNmQax"} style={{backgroundColor: Rl_qLe3MC}}/>)}<motion.div className={"framer-48ytin"} data-framer-name={"H"} layoutDependency={layoutDependency} layoutId={"sgcbBsV6E"} style={{backgroundColor: Rl_qLe3MC, rotate: 90}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZPULr.framer-mnr6bg, .framer-ZPULr .framer-mnr6bg { display: block; }", ".framer-ZPULr.framer-1xcu7rj { height: 16px; overflow: visible; position: relative; width: 16px; }", ".framer-ZPULr .framer-2rakpf, .framer-ZPULr .framer-48ytin { bottom: 0px; flex: none; left: calc(50.00000000000002% - 2px / 2); overflow: visible; position: absolute; top: 0px; width: 2px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Rl_qLe3MC":"color","gUDzlhgLq":"showVertical"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBvyOYDKED: React.ComponentType<Props> = withCSS(Component, css, "framer-ZPULr") as typeof Component;
export default FramerBvyOYDKED;

FramerBvyOYDKED.displayName = "Plus icon";

FramerBvyOYDKED.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerBvyOYDKED, {Rl_qLe3MC: {defaultValue: "var(--token-88d5059b-bc5d-4e0a-ad79-b21e9a2c4948, rgb(10, 10, 10)) /* {\"name\":\"Black\"} */", title: "Color", type: ControlType.Color}, gUDzlhgLq: {defaultValue: true, title: "Show vertical", type: ControlType.Boolean}} as any)

addFonts(FramerBvyOYDKED, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})